import { ICrudLocale } from '@app/i18n/interface';

export const USER_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  PATIENT: {
    LABEL: {
      SINGULAR: 'Paciente',
      PLURAL: 'Pacientes',
    },
    RISK: {
      CR: 'R.C ',
      CR_VALUE: 'Riesgo cardiovascular',
      PR: 'R.P ',
      PR_VALUE: 'Riesgo Progresión',
      MR: 'R.M ',
      MR_VALUE: 'Riesgo mortalidad',
    },
    RISK_LEGEND: {
      RL: 'Riesgo leve',
      RM: 'Riesgo moderado',
      RS: 'Riesgo severo',
      SR: 'Riesgo no aplicado',
    },
    FIELD: {
      NAME: 'Nombre',
      NAME_LASTNAME: 'Nombre y apellidos',
      USERNAME: 'Usuario',
      IA_RESULTS: 'Resultados de AI',
      EMAIL: 'Contacto',
      GENDER: 'Género',
      AGE: 'Edad',
      WEIGHT: 'Peso',
      SIZE: 'Talla',
      IMC: 'IMC',
      ENABLED: 'Activo',
      LASTNAME: 'Apellido',
      PASSWORD: 'Contraseña',
      MOBILE: 'Teléfono',
      ADDRESS: 'Dirección',
      PROVINCE: 'Província',
      ROL: 'Rol',
      DNI: 'Dni',
      CP: 'Código postal',
      GROUPS: 'Tipo',
      CENTER: 'Centro',
      STATUS: 'Estado',
      ACTIONS: 'Acciones',
      OBX: 'OBX',
      FACTORS: 'Variable',
      RESULT: 'Resultado',
      OBSERVATIONS: 'Observaciones',
      BIRTHDATE: 'Fecha de nacimiento',
      SELF_GENERATED_PASSWORD: 'Contraseña autogenerada',
      COLLEGIATE_NUMBER: 'Número de colegiado',
      LAST_LOGIN: 'Último acceso',
    },
    LIST: {
      TITLE: 'Listado de usuarios',
      FILTERS: 'Filtros',
      BTN: 'Nuevo usuario',
    },
    DETAILS: {
      TITLE: 'Detalles de usuarios',
      FILTERS: 'Filtros',
      BTN: 'Nuevo usuario',
      TAB_1: 'Resultados AI',
      TAB_2: 'Variables',
      TAB_3: 'Gráficos',
      TAB_4: 'Información del Paciente',
      TAB_1_INFO: 'La inteligencia artificial ayuda a predecir problemas de salud en pacientes al analizar grandes volúmenes de datos y detectar patrones ocultos. Utiliza algoritmos avanzados para identificar riesgos, prever complicaciones y personalizar tratamientos. Esto permite a los profesionales de la salud tomar decisiones informadas y proactivas, mejorando así los resultados y la calidad de la atención médica.',
      TAB_2_HEADER: 'Variables demográficas y antropomórficas',
      TAB_4_HEADER: 'Datos del paciente',
    },
  },
};
